/* .games-page {
    text-align: center;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 30px;
  }
  
  .games-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    justify-items: center;
  }
  
  .game-card {
    position: relative;
    width: 250px;
    height: 200px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .game-card-overlay {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .game-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .lock-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    color: #fff;
    background: rgba(0, 0, 0, 0.6);
    padding: 10px;
    border-radius: 50%;
  }
  
  .game-card-overlay.locked .game-card-image {
    filter: grayscale(100%);
    opacity: 0.7;
  }
  
  .game-title {
    margin-top: 10px;
    font-size: 1rem;
    font-weight: bold;
    color: #333;
  }
   */

   /* .games-page {
    padding: 20px;
    text-align: center;
    background: linear-gradient(45deg, #ffffff, #2f63dc); 
    color: white; 
} */
.games-page {
  padding: 20px;
  text-align: center;
  /* background: linear-gradient(15deg, #ff5f5f, #7bdbfe, #90b7ff, #f1ff70);  */
  color: rgb(0, 0, 0); /* Ensures text is readable */
}
  
  h1 {
    font-size: 2rem;
    margin-bottom: 10px;
    /* color: azure; */
  }
  
  p {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #555;
  }
  
  .games-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 10fr)); /* Ensures 2 cards per row on large screens and 1 card per row on smaller screens */
    gap: 20px; /* Spacing between cards */
    justify-content: center;
    margin: auto;
  }
  
  .game-card {
    position: relative;
    overflow: hidden;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
  }
  
  .game-card:hover {
    transform: translateY(-5px); /* Add hover effect */
  }
  
  .game-card-overlay {
    position: relative;
    border-radius: 10px;
  }
  
  .game-card-overlay.locked::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    border-radius: 10px;
    z-index: 1; /* Ensures the overlay is above the image */
  }
  
  .game-card-image {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 10px;
    z-index: 0; /* Ensures the image is below the overlay */
  }
  
  .lock-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    color: rgba(255, 255, 255, 0.9);
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px;
    border-radius: 50%;
    z-index: 2; /* Ensures the lock icon is above the overlay */
  }
  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    color: rgba(255, 255, 255, 0.9);
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px;
    border-radius: 50%;
    z-index: 2; /* Ensures the lock icon is above the overlay */
  }
  
  
  .game-title {
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    color: #333;
  }
  
  
  /* Responsive Design for Mobile View */
  @media (max-width: 768px) {
    .games-grid {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr)); /* 1 card per row on mobile screens */
    }
  
    .game-card {
      margin: 0 auto; /* Center the card */
    }
  }
  
  